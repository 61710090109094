import Vue from 'vue'

Vue.directive('role', {
  bind (el, binging, vnode, oldVnode) {
    const btnRole = JSON.parse(sessionStorage.getItem('btnRole'))
    const isAdmin = parseInt(sessionStorage.getItem('isAdmin'))
    if (isAdmin === 1) {
      return
    }
    if (!btnRole) {
      el.style.display = 'none'
      el.setAttribute('disabled', true)
      el.classList.add('is-disabled')
    } else {
      if (btnRole.indexOf(binging.value) === -1) {
        el.style.display = 'none'
        el.setAttribute('disabled', true)
        el.classList.add('is-disabled')
      }
    }
  }
})
