import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI, { Message } from 'element-ui';
// import './plugins/element.js'
import axios from 'axios'
import echarts from 'echarts'
import BaiduMap from 'vue-baidu-map'
import moment from 'moment'
import '@/icons' // icon
import './assets/js/dialogDrag'
import './assets/js/role'
import './assets/css/mediaQuery.scss'

Vue.use(ElementUI)
// 时间插件
Vue.filter('dateFormat', function (dateStr, pattern = 'YYYY-MM-DD') {
  if (!dateStr) {
    return '暂无数据'
  }
  else {
    return moment(dateStr).format(pattern)
  }
})

// 时间插件
Vue.filter('monthFormat', function (dateStr, pattern = 'YYYY-MM') {
  if (!dateStr) {
    return '暂无数据'
  }
  else {
    return moment(dateStr).format(pattern)
  }
})

// 时间插件
Vue.filter('timeFormat', function (dateStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  if (!dateStr) {
    return '暂无数据'
  }
  else {
    return moment(dateStr).format(pattern)
  }
})
axios.defaults.baseURL = 'http://120.55.61.249:9002'
// axios.defaults.baseURL = 'http://192.168.31.36:9003'
// axios.defaults.baseURL = 'http://ptf892.natappfree.cc'

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (config.url.indexOf('https://open.ys7.com') === -1) {
      if (sessionStorage.getItem('token')) {
        config.headers.Authorization = sessionStorage.getItem('token')
      }
      if(config.method==='get'){
        const areaCode=sessionStorage.getItem("areaCode")
        if(config.params===undefined){
          config.params={}
        }
        if(areaCode) {
          Object.assign(config.params,{areaCode})
        }
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

// http response 拦截器

axios.interceptors.response.use(
  async response => {
    if (response.data.code !== undefined) {
      const {code, msg} = response.data
      if (code !== 200) {
        if (code < 500) Message.warning(msg || '服务异常')
        if (code >= 500) Message.error(msg || '服务异常')
        if (code === 4001) {
          sessionStorage.clear()
          router.push('/')
        }
        return Promise.reject(response.data)
      }
    }
    return response
  },
  error => {
    // if (error.response) { }
    // 返回接口返回的错误信息
    return Promise.reject(error.response.data)
  })

Vue.use(BaiduMap, {
  /* 需要注册百度地图开发者来获取你的ak */
  ak: 'ahVb0clSVLITXPpNieRI7DXL47tgHvcE'
})

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts

document.title = '智慧消防物联网监管平台'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
