import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/home/GIS',
        name: 'GIS',
        component: () => import('../views/GIS/GIS.vue'),
        meta: {keepAlive: true}
      }, // 行政区域
      {
        path: '/home/area',
        name: 'Area',
        component: () => import('../views/setting/Area.vue'),
        meta: {keepAlive: true}
      }, // 行政区域
      {
        path: '/home/group',
        name: 'Group',
        component: () => import('../views/setting/Group.vue'),
        meta: {keepAlive: true}
      }, // 单位分组
      {
        path: '/home/role',
        name: 'Role',
        component: () => import('../views/setting/Role.vue'),
        meta: {keepAlive: true}
      }, // 角色分组
      {
        path: '/home/organization/:id',
        name: 'Organization',
        component: () => import('../views/setting/Organization.vue'),
        meta: {keepAlive: true}
      }, // 组织机构
      {
        path: '/home/building',
        name: 'Building',
        component: () => import('../views/region/Building.vue'),
        meta: {keepAlive: true}
      }, // 建筑物
      {
        path: '/home/devicestat',
        name: 'DeviceStat',
        component: () => import('../views/device/DeviceStat.vue'),
        meta: {keepAlive: true}
      }, // 设备统计
      {
        path: '/home/hawkeye',
        name: 'HawkEye',
        component: () => import('../views/device/Hawkeye.vue'),
        meta: {keepAlive: true}
      }, // 鹰眼
      {
        path: '/home/company/:id',
        name: 'Company',
        component: () => import('../views/unit/Company.vue'),
        meta: {keepAlive: true},
        children: [
          {
            path: '/home/companyinfo/department',
            name: 'Company-Department',
            component: () => import('../views/company/organization/Department.vue'),
            meta: {keepAlive: true},
          }, // 单位部门
          {
            path: '/home/companyinfo/role',
            name: 'Company-CompanyRole',
            component: () => import('../views/company/organization/Role.vue'),
            meta: {keepAlive: true},
          }, // 单位角色
          {
            path: '/home/companyinfo/member',
            name: 'Company-Member',
            component: () => import('../views/company/organization/Member.vue'),
            meta: {keepAlive: true},
          }, // 单位人员
          {
            path: '/home/companyinfo/info',
            name: 'CompanyInfo',
            component: () => import('../views/company/infoFile/Info.vue'),
            children: [
              {
                path: '/home/companyinfo/info/base',
                name: 'Base',
                component: () => import('../views/company/infoFile/File/Base.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/photo',
                name: 'Photo',
                component: () => import('../views/company/infoFile/File/Photo.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/licence',
                name: 'Licence',
                component: () => import('../views/company/infoFile/File/Licence.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/layout',
                name: 'Layout',
                component: () => import('../views/company/infoFile/File/Layout.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/contract',
                name: 'Contract',
                component: () => import('../views/company/infoFile/File/Contract.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/certificate',
                name: 'Certificate',
                component: () => import('../views/company/infoFile/File/Certificate.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/keyparts',
                name: 'Keyparts',
                component: () => import('../views/company/infoFile/File/KeyParts.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/notice',
                name: 'Notice',
                component: () => import('../views/company/infoFile/File/Notice.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/committed',
                name: 'Committed',
                component: () => import('../views/company/infoFile/File/Committed.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/realname',
                name: 'RealName',
                component: () => import('../views/company/infoFile/File/RealName.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/drill',
                name: 'Drill',
                component: () => import('../views/company/infoFile/File/Drill.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/system',
                name: 'System',
                component: () => import('../views/company/infoFile/File/System.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/duty',
                name: 'Duty',
                component: () => import('../views/company/infoFile/File/Duty.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/apply',
                name: 'Apply',
                component: () => import('../views/company/infoFile/File/Apply.vue'),
                meta: {keepAlive: true},
              },
              {
                path: '/home/companyinfo/info/register',
                name: 'Register',
                component: () => import('../views/company/infoFile/File/Register.vue'),
                meta: {keepAlive: true},
              }
            ]
          }, // 单位信息档案
          {
            path: '/home/companyinfo/NFC',
            name: 'NFC',
            component: () => import('../views/company/fireControl/NFC.vue'),
            meta: {keepAlive: true},
          }, // NFC巡检
          {
            path: '/home/companyinfo/inspect',
            name: 'Inspect',
            component: () => import('../views/supervise/FireInspect.vue'),
            meta: {keepAlive: true},
          }, // 防火巡检
          {
            path: '/home/companyinfo/dutyrecord',
            name: 'DutyRecord',
            component: () => import('../views/company/fireControl/DutyRecord.vue'),
            meta: {keepAlive: true},
          }, // 消防控制室值班记录
          {
            path: '/home/companyinfo/devicereport',
            name: 'DeviceReport',
            component: () => import('../views/company/fireControl/DeviceReport.vue'),
            meta: {keepAlive: true},
          }, // 设施维护保养报告备案表
          {
            path: '/home/companyinfo/correct',
            name: 'Correct',
            component: () => import('../views/supervise/Correct.vue'),
            meta: {keepAlive: true},
          }, // 责令整改
          {
            path: '/home/companyinfo/water',
            name: 'Water',
            component: () => import('../views/company/fireControl/Water.vue'),
            meta: {keepAlive: true},
          }, // 水源管理
          {
            path: '/home/companyinfo/device',
            name: 'CompanyinfoDevice',
            component: () => import('../views/company/fireControl/Device.vue'),
            meta: {keepAlive: true},
          }, // 物联设备
          {
            path: '/home/companyinfo/alarmInfo',
            name: 'CompanyinfoAlarmInfo',
            component: () => import('../views/company/fireControl/AlarmInfo.vue'),
            meta: {keepAlive: true},
          },
        ]
      },
      {
        path: '/home/brigade',
        name: 'Brigade',
        component: () => import('../views/unit/FireBrigade.vue'),
        meta: {keepAlive: true}
      }, // 站队
      {
        path: '/home/device',
        name: 'Device',
        component: () => import('../views/device/Device.vue'),
        meta: {keepAlive: true}
      }, // 设备
      {
        path: '/home/devicemodel',
        name: 'Devicemodel',
        component: () => import('../views/device/DeviceModel.vue'),
        meta: {keepAlive: true}
      }, // 设备
      {
        path: '/home/water',
        name: 'WaterOut',
        component: () => import('../views/device/Water.vue'),
        meta: {keepAlive: true}
      }, // 室外水源
      {
        path: '/home/monitor',
        name: 'Monitor',
        component: () => import('../views/device/Monitor.vue'),
        meta: {keepAlive: true}
      }, // 监控
      {
        path: '/home/willupload',
        name: 'WillUpload',
        component: () => import('../views/device/WillUploadDevice.vue'),
        meta: {keepAlive: true}
      }, // 待上报设备
      {
        path: '/home/threshold',
        name: 'Threshold',
        component: () => import('../views/device/Threshold.vue'),
        meta: {keepAlive: true}
      }, // 设备阈值
      {
        path: '/home/account/:id',
        name: 'Account',
        component: () => import('../views/setting/Account.vue'),
        meta: {keepAlive: true}
      }, // 账户
      {
        path: '/home/unitaccount',
        name: 'UnitAccount',
        component: () => import('../views/setting/UnitAccount.vue'),
        meta: {keepAlive: true}
      }, // 单位账户
      {
        path: '/home/dict',
        name: 'Dict',
        component: () => import('../views/setting/Dict.vue'),
        meta: {keepAlive: true}
      }, // 字典管理
      {
        path: '/home/oplog',
        name: 'OpLog',
        component: () => import('../views/setting/OpLog.vue'),
        meta: {keepAlive: true}
      }, // 操作日志
      {
        path: '/home/stat/company',
        name: 'StatComapny',
        component: () => import('../views/stat/deviceStat/Company.vue'),
        meta: {keepAlive: true}
      }, // 安装公司统计设备
      {
        path: '/home/stat/device',
        name: 'StatDevice',
        component: () => import('../views/stat/deviceStat/Device.vue'),
        meta: {keepAlive: true}
      }, // 安装公司统计设备
      {
        path: '/home/stat/unit',
        name: 'StatUnit',
        component: () => import('../views/stat/deviceStat/Unit.vue'),
        meta: {keepAlive: true}
      }, // 安装公司统计设备
      {
        path: '/home/stat/member',
        name: 'StatMember',
        component: () => import('../views/stat/deviceStat/Member.vue'),
        meta: {keepAlive: true}
      }, // 安装公司统计设备
      {
        path: '/home/stat/unitnum',
        name: 'StatUnitNum',
        component: () => import('../views/stat/workloadStat/UnitNum.vue'),
        meta: {keepAlive: true}
      }, // 工人录入单位统计设备
      {
        path: '/home/stat/install',
        name: 'Install',
        component: () => import('../views/stat/Install.vue'),
        meta: {keepAlive: true}
      }, // 安装数据
      {
        path: '/home/warning/current',
        name: 'CurWarning',
        component: () => import('../views/warning/Current.vue'),
        meta: {keepAlive: true}
      }, // 当前告警
      {
        path: '/home/warning/history',
        name: 'HistoryWarning',
        component: () => import('../views/warning/History.vue'),
        meta: {keepAlive: true}
      }, // 历史告警
      {
        path: '/home/warning/stat',
        name: 'StatWarning',
        component: () => import('../views/warning/Stat.vue'),
        meta: {keepAlive: true}
      }, // 告警统计
      {
        path: '/home/warning/danger',
        name: 'Danger',
        component: () => import('../views/warning/Danger.vue'),
        meta: {keepAlive: true}
      }, // 隐患事件
      {
        path: '/home/warning/fault',
        name: 'Fault',
        component: () => import('../views/warning/Fault.vue'),
        meta: {keepAlive: true}
      }, // 隐患事件
      {
        path: '/home/warning/operation',
        name: 'Operation',
        component: () => import('../views/warning/Operation.vue'),
        meta: {keepAlive: true}
      }, // 设备运行信息
      {
        path: '/home/library',
        name: 'Library',
        component: () => import('../views/Library.vue'),
        meta: {keepAlive: true}
      }, // 设备库
      {
        path: '/home/NFC',
        name: 'NFC',
        component: () => import('../views/company/fireControl/NFC.vue'),
        meta: {keepAlive: true}
      }, // NFC巡检
      {
        path: '/home/dutyrecord',
        name: 'DutyRecord',
        component: () => import('../views/company/fireControl/DutyRecord.vue'),
        meta: {keepAlive: true}
      }, // 消防控制室值班记录
      {
        path: '/home/devicereport',
        name: 'DeviceReport',
        component: () => import('../views/company/fireControl/DeviceReport.vue'),
        meta: {keepAlive: true}
      }, // 设施维护保养报告备案表
      {
        path: '/home/estimate',
        name: 'Estimate',
        component: () => import('../views/company/fireControl/Estimate.vue'),
        meta: {keepAlive: true}
      }, // 风险评估
      {
        path: '/home/score/:id',
        name: 'Score',
        component: () => import('../views/company/fireControl/Score.vue'),
        meta: {keepAlive: true}
      }, // 风险评估报告
      {
        path: '/home/notice',
        name: 'AdminNotice',
        component: () => import('../views/supervise/Notice.vue'),
        meta: {keepAlive: true}
      }, // 通知公告
      {
        path: '/home/law',
        name: 'Law',
        component: () => import('../views/supervise/Law.vue'),
        meta: {keepAlive: true}
      }, // 法律法规
      {
        path: '/home/propaganda',
        name: 'Propaganda',
        component: () => import('../views/supervise/Propaganda.vue'),
        meta: {keepAlive: true}
      }, // 消防宣传
      {
        path: '/home/inspect',
        name: 'Inspect',
        component: () => import('../views/supervise/FireInspect.vue'),
        meta: {keepAlive: true}
      }, // 防火巡检
      {
        path: '/home/record',
        name: 'Record',
        component: () => import('../views/supervise/Record.vue'),
        meta: {keepAlive: true}
      }, // 消防检查记录
      {
        path: '/home/task',
        name: 'Task',
        component: () => import('../views/supervise/Task.vue'),
        meta: {keepAlive: true}
      }, // 检查任务
      {
        path: '/home/taskinfo/:id',
        name: 'TaskInfo',
        component: () => import('../views/supervise/TaskInfo.vue'),
        meta: {keepAlive: true}
      }, // 检查任务详情
      {
        path: '/home/correct',
        name: 'Correct',
        component: () => import('../views/supervise/Correct.vue'),
        meta: {keepAlive: true}
      }, // 责令整改
      // 单位
      {
        path: '/home/unit/home',
        name: 'UnitHome',
        component: () => import('../views/company/UnitHome.vue'),
        meta: {keepAlive: true}
      }, // 单位首页
      {
        path: '/home/unit/department',
        name: 'Department',
        component: () => import('../views/company/organization/Department.vue'),
        meta: {keepAlive: true}
      }, // 单位部门
      {
        path: '/home/unit/role',
        name: 'CompanyRole',
        component: () => import('../views/company/organization/Role.vue'),
        meta: {keepAlive: true}
      }, // 单位角色
      {
        path: '/home/unit/member',
        name: 'Member',
        component: () => import('../views/company/organization/Member.vue'),
        meta: {keepAlive: true}
      }, // 单位人员
      {
        path: '/home/unit/link',
        name: 'Link',
        component: () => import('../views/company/organization/Link.vue'),
        meta: {keepAlive: true},
        children: [
          {
            path: '/home/link/NFC',
            name: 'LinkNFC',
            component: () => import('../views/company/fireControl/NFC.vue'),
            meta: {keepAlive: true},
          }, // NFC巡检
          {
            path: '/home/link/inspect',
            name: 'Inspect',
            component: () => import('../views/supervise/FireInspect.vue'),
            meta: {keepAlive: true},
          }, // 防火巡检
          {
            path: '/home/link/dutyrecord',
            name: 'DutyRecord',
            component: () => import('../views/company/fireControl/DutyRecord.vue'),
            meta: {keepAlive: true},
          }, // 消防控制室值班记录
          {
            path: '/home/link/devicereport',
            name: 'DeviceReport',
            component: () => import('../views/company/fireControl/DeviceReport.vue'),
            meta: {keepAlive: true},
          }, // 设施维护保养报告备案表
          {
            path: '/home/link/correct',
            name: 'Correct',
            component: () => import('../views/supervise/Correct.vue'),
            meta: {keepAlive: true},
          } // 责令整改
        ]
      }, // 单位人员
      {
        path: '/home/unit/info',
        name: 'CompanyInfo',
        component: () => import('../views/company/infoFile/Info.vue'),
        meta: {keepAlive: true},
        children: [
          {
            path: '/home/unit/info/base',
            name: 'Base',
            component: () => import('../views/company/infoFile/File/Base.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/photo',
            name: 'Photo',
            component: () => import('../views/company/infoFile/File/Photo.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/licence',
            name: 'Licence',
            component: () => import('../views/company/infoFile/File/Licence.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/layout',
            name: 'Layout',
            component: () => import('../views/company/infoFile/File/Layout.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/contract',
            name: 'Contract',
            component: () => import('../views/company/infoFile/File/Contract.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/certificate',
            name: 'Certificate',
            component: () => import('../views/company/infoFile/File/Certificate.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/keyparts',
            name: 'Keyparts',
            component: () => import('../views/company/infoFile/File/KeyParts.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/notice',
            name: 'Notice',
            component: () => import('../views/company/infoFile/File/Notice.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/committed',
            name: 'Committed',
            component: () => import('../views/company/infoFile/File/Committed.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/realname',
            name: 'RealName',
            component: () => import('../views/company/infoFile/File/RealName.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/drill',
            name: 'Drill',
            component: () => import('../views/company/infoFile/File/Drill.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/system',
            name: 'System',
            component: () => import('../views/company/infoFile/File/System.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/duty',
            name: 'Duty',
            component: () => import('../views/company/infoFile/File/Duty.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/apply',
            name: 'Apply',
            component: () => import('../views/company/infoFile/File/Apply.vue'),
            meta: {keepAlive: true},
          },
          {
            path: '/home/unit/info/register',
            name: 'Register',
            component: () => import('../views/company/infoFile/File/Register.vue'),
            meta: {keepAlive: true},
          }
        ]
      }, // 单位信息档案
      {
        path: '/home/unit/todolist',
        name: 'ToDoList',
        component: () => import('../views/company/fireControl/ToDoList.vue'),
        meta: {keepAlive: true}
      }, // 待办事项
      {
        path: '/home/unit/score',
        name: 'Score',
        component: () => import('../views/company/fireControl/Score.vue'),
        meta: {keepAlive: true}
      }, // 单位风险评估
      {
        path: '/home/unit/NFC',
        name: 'NFC',
        component: () => import('../views/company/fireControl/NFC.vue'),
        meta: {keepAlive: true}
      }, // NFC巡检
      {
        path: '/home/unit/inspect',
        name: 'Inspect',
        component: () => import('../views/supervise/FireInspect.vue'),
        meta: {keepAlive: true}
      }, // 防火巡检
      {
        path: '/home/unit/dutyrecord',
        name: 'DutyRecord',
        component: () => import('../views/company/fireControl/DutyRecord.vue'),
        meta: {keepAlive: true}
      }, // 消防控制室值班记录
      {
        path: '/home/unit/devicereport',
        name: 'DeviceReport',
        component: () => import('../views/company/fireControl/DeviceReport.vue'),
        meta: {keepAlive: true}
      }, // 设施维护保养报告备案表
      {
        path: '/home/unit/train',
        name: 'Train',
        component: () => import('../views/company/fireControl/Train.vue'),
        meta: {keepAlive: true}
      }, // 消防培训
      {
        path: '/home/unit/skilltrain',
        name: 'SkillTrain',
        component: () => import('../views/company/fireControl/SkillTrain.vue'),
        meta: {keepAlive: true},
      }, // 一警六员
      {
        path: '/home/unit/water',
        name: 'Water',
        component: () => import('../views/company/fireControl/Water.vue'),
        meta: {keepAlive: true}
      }, // 水源管理
      {
        path: '/home/unit/device',
        name: 'Device',
        component: () => import('../views/company/fireControl/Device.vue'),
        meta: {keepAlive: true}
      }, // 物联设备
      {
        path: '/home/unit/record',
        name: 'UnitRecord',
        component: () => import('../views/RecordFullScreen.vue'),
        meta: {keepAlive: true}
      }
    ]
  },
  {
    path: '/camera',
    name: 'CameraCopy',
    component: () => import('../views/CameraCopy.vue'),
    meta: {keepAlive: true},
  },
  {
    path: '/record',
    name: 'RecordFullScreen',
    component: () => import('../views/RecordFullScreen.vue'),
    meta: {keepAlive: true},
  },
  {
    path: '/zg119/platformJump',
    name: 'ProvincePlatform',
    component: () => import('../views/province/ProvincePlatform.vue'),
    meta: {keepAlive: true},
  }, // 省平台告警页面
  {
    path: '/zg119/provinceGroup',
    name: 'ProvinceGroup',
    component: () => import('../views/province/ProvinceGroup.vue'),
    meta: {keepAlive: true},
  },
  {
    path: '/zg119/provinceVideo',
    name: 'ProvinceVideo',
    component: () => import('../views/province/ProvinceVideo.vue'),
    meta: {keepAlive: true},
  },
  {
    path: '/zg119/provinceMonitor',
    name: 'ProvinceMonitor',
    component: () => import('../views/province/ProvinceMonitor.vue'),
    meta: {keepAlive: true},
  },
  {
    path: '/devicemap',
    name: 'ProvinceMap',
    component: () => import('../views/province/ProvinceMap.vue'),
    meta: {keepAlive: true},
  },
  {
    path: '/home/camera',
    name: 'ProvinceHawkeye',
    component: () => import('../views/province/ProvinceHawkeye.vue'),
    meta: {keepAlive: true},
  }
]

const router = new VueRouter({
  routes
})

const whiteList = ['/home/camera', '/']
router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.path) || to.path.includes('/zg119')) {
    next()
  }
  else {
    const token = sessionStorage.getItem("token")
    if (token == null) {
      next('/')
    }
    else next()
  }

  // else {
  //   if (to.path === '/') {
  //     if (token == null) {
  //       next()
  //     }
  //     else next("/home/GIS")
  //   }
  //   else {
  //     if (token == null) {
  //       next("/")
  //     }
  //     else next()
  //   }
  // }
})
export default router
