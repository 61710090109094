<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

.hawkeyeDialog {
  pointer-events: none;

  .el-dialog {
    pointer-events: auto;
  }
}

.el-icon-circle-check {
  color: #67c23a;
}

.el-cascader-menu {
  min-width: 180px;
  height: 200px;
  overflow: hidden;
}

.el-cascader-panel {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
}

.el-tabs__content {
  width: 100%;
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0 !important;
}

// 去除为元素
.noangle {
  box-shadow: none !important;
}

.noangle::after {
  display: none;
}

.noangle::before {
  display: none;
}

.table-dropdown-menu {
  background-color: rgba($color: #00246b, $alpha: 0.9) !important;
  border: 1px solid #ccc !important;

  .el-dropdown-menu__item {
    background-color: transparent !important;
    color: #eee !important;
    text-align: center;
    &:hover {
      background-color: #165191 !important;
      color: #fff !important;
    }
  }
}


.el-popover {
  background-color: rgba($color: #00246b, $alpha: 0.9) !important;
  border: 1px solid #ccc !important;

  .el-form-item__label {
    color: #fff;
  }

  .el-input__inner {
    background-color: rgba(7, 104, 159, 0.15);
    color: #fff;

    .el-range-input {
      background-color: transparent;
      color: #fff;
    }
  }

  .el-range-separator {
    color: #fff !important;
  }
}

/* 滚动条 */
::-webkit-scrollbar-thumb:horizontal {
  /*水平滚动条的样式*/
  width: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent; /*滚动条的背景颜色*/
}

::-webkit-scrollbar {
  width: 10px; /*滚动条的宽度*/
  height: 8px; /*滚动条的高度*/
}

::-webkit-scrollbar-thumb:vertical {
  /*垂直滚动条的样式*/
  height: 50px;
  background-color: rgb(8, 107, 143);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  /*滚动条的hover样式*/
  height: 50px;
  background-color: rgba(8, 107, 143, 0.8);
  border-radius: 8px;
}
</style>
